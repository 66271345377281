import { EventBusNames } from '@/utils/EventBus';
import { toggleModal } from '@/utils/Helper';
import { defineComponent } from "vue";

export default defineComponent({
    data() {
        return {
            submitting: false
        }
    },
    methods: {
        async handleAccountDelete() {
            try {
                this.submitting = true
                await this.$userService.remove();
                this.$router.push(this.$paths.login);
                this.$eventBus.$emit(EventBusNames.SHOW_TOAST, {
                    title: "Sua conta foi deletada",
                    msg: `Todas as suas informações foram deletadas com sucesso.`,
                });
                toggleModal('account_delete')
            } catch (error) {
                console.log(error);
            } finally {
                this.submitting = false
            }
        }
    }
})