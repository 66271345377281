import { useMainStore } from '@/store'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import paths from './paths'
import qs from "qs";
import UserService from '@/services/UserService';

const routes: Array<RouteRecordRaw> = [
  {
    path: paths.home,
    name: 'Home',
    alias: '/',
    component: () => import('../views/Home/Home.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: paths.login,
    name: 'Login',
    component: () => import('../views/Login/Login.vue'),
  },
  {
    path: paths.forgot_password,
    name: 'ForgotPassword',
    component: () => import('../views/ForgotPassword/ForgotPassword.vue'),
  },
  {
    path: paths.reset_password,
    name: 'ResetPassword',
    component: () => import('../views/ResetPassword/ResetPassword.vue'),
  },
  {
    alias: paths.register,
    path: `${paths.register}/:step?`,
    name: 'Register',
    component: () => import('../views/Register/Register.vue'),
  },
  {
    path: paths.onHolding,
    name: 'OnHolding',
    component: () => import('../views/Register/OnHolding/OnHolding.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: paths.addBankAccount,
    name: 'AddBankAccount',
    component: () => import('../views/Register/AddBankAccount/AddBankAccount.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: paths.chat.list,
    name: 'Chat',
    component: () => import('../views/Chat/Chat.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.financial.list}/:path?`,
    name: 'Financial',
    component: () => import('../views/Financial/Financial.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: paths.appointments.list,
    name: 'Appointments',
    component: () => import('../views/Appointments/Appointments.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.appointments.room}/:id`,
    name: 'AppointmentRoom',
    component: () => import('../views/Appointments/AppointmentRoom/AppointmentRoom.vue'),
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: `${paths.more}/:path?`,
    name: 'More',
    component: () => import('../views/More/More.vue'),
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: paths.psiappPlus,
    name: 'PsiappPlus',
    component: () => import('../views/PsiappPlus/PsiappPlus.vue'),
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: paths.not_found,
    name: 'NotFound',
    component: () => import('../views/NotFound/NotFound.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'NotFound'
    }
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach(async (to, from, next) => {
  const store = useMainStore()
  const session = store.session

  if (to.query.token && to.query.user_id) {
    const logged = session && session.id == parseInt(to.query.user_id as string)
    if (!logged) {
      const login = await new UserService().loginWithBearerToken(to.query)
      if (!login) store.clearSession()
    }
    delete to.query.token;
    delete to.query.user_id;
    const newPath = `${to.path}?${qs.stringify(to.query)}`;
    next(newPath);
    return
  }

  if (to.meta.requiresAuth && !session) {
    next(paths.login);
  } else {
    if (to.meta.requiresAuth
      && to.path !== paths.onHolding
      && to.path !== paths.addBankAccount
      && session
      && (!session?.approved || !session?.stripe_id)
      // && (!session?.approved || !session?.stripe_id || !session?.charges_enabled)
    ) {
      next(paths.onHolding);
      return
    }
    next();
  }
});

export default router
