
import { useMainStore } from "@/store";
import { toggleModal } from "@/utils/Helper";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { mapState } from "pinia";
import { defineComponent } from "vue";

export default defineComponent({
    setup() {
        const v$ = useVuelidate();
        return { v$ };
    },
    computed: {
        ...mapState(useMainStore, ["session"]),
    },
    mounted() {
        this.loading = true;
        this.updateSession();
        this.getBanks();
        this.setupForm();
    },
    data() {
        return {
            loading: true,
            submitting: false,
            banks: [],
            bankAccount: {} as any,
            connectAccount: {} as any,
            editing: false,
            warning: "",
            form: {
                agencia: "",
                conta: "",
                bank_code: "",
                agencia_dv: "",
                conta_dv: "",
                // document_number: "",
            },
        };
    },
    validations() {
        return {
            form: {
                agencia: {
                    required: helpers.withMessage("Informe a agência", required),
                },
                conta: {
                    required: helpers.withMessage("Informe a conta", required),
                },
                bank_code: {
                    required: helpers.withMessage("", required),
                },

                agencia_dv: {},
                conta_dv: {},
            },
        };
    },
    methods: {
        setupForm() {
            this.warning = `O tipo da sua conta é de pessoa ${this.session?.connect_account.business_type == "individual"
                ? "física"
                : "jurídica"
                }, você só pode alterar sua conta bancária para outra conta de pessoa ${this.session?.connect_account.business_type == "individual"
                    ? "física"
                    : "jurídica"
                }.`;

            this.connectAccount = this.session?.connect_account;
            this.bankAccount = this.session?.bank_account;
            this.editing = !this.bankAccount;

            this.form = {
                agencia: this.session?.bank_account?.agencia || "",
                conta: this.session?.bank_account?.conta || "",
                bank_code: this.session?.bank_account?.bank_code || "",
                agencia_dv: this.session?.bank_account?.agencia_dv || "",
                conta_dv: this.session?.bank_account?.conta_dv || "",
                // document_number: this.session?.bank_account?.document_number || "",
            };
        },
        async handleUpdateBankAccount() {
            try {
                this.submitting = true;
                await this.$userService.updateMyBank(this.form);
                await this.$userService.syncSession();
                this.handleEdit();
            } catch (error) {
                console.log(error);
            } finally {
                this.submitting = false;
            }
        },
        async getBanks() {
            try {
                const res = await this.$userService.getBanks();
                this.banks = res.data.map((elem: any) => ({
                    ...elem,
                    id: elem.code,
                    title: elem.name,
                }));
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        async updateSession() {
            try {
                await this.$userService.syncSession();
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        handleEdit() {
            if (this.editing || !this.bankAccount) {
                this.editing = !this.editing;
                this.v$.$reset();
                this.setupForm();
            } else {
                toggleModal("update_bank_account_warning");
            }
        },
        handleConfirmEdit() {
            toggleModal("update_bank_account_warning");
            this.editing = !this.editing;
        },
        async handleChangeAccountType() {
            try {
                if (this.session) {
                    this.submitting = true
                    const res = await this.$userService.stripeConnectReset(this.session.id)
                    window.open(res.data.url, "_blank");
                }
            } catch (error) {
                console.error(error)
            } finally {
                toggleModal("update_account_type_warning");
                this.submitting = false
            }
        },
        onChangeAccountType() {
            toggleModal("update_account_type_warning");
        }
    },
});