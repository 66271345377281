import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-368584ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card py-3 sticky-md-top" }
const _hoisted_2 = { class: "card-header px-3 pt-1" }
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = { class: "text-subtitle mb-0 mx-3" }
const _hoisted_5 = { class: "d-flex justify-content-around" }
const _hoisted_6 = { class: "text-label" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "d-flex align-items-center justify-content-between" }
const _hoisted_9 = {
  key: 0,
  class: "rounded-circle mt-1 appointment-circle bg-secondary ms-1"
}
const _hoisted_10 = {
  key: 1,
  class: "rounded-circle mt-1 appointment-circle bg-primary mx-1"
}
const _hoisted_11 = {
  key: 2,
  class: "rounded-circle mt-1 appointment-circle bg-primary25 me-1"
}
const _hoisted_12 = {
  key: 3,
  class: "rounded-circle mt-1 appointment-circle bg-white"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("i", {
          class: "fa-solid fa-chevron-circle-left text-secondary fa-xl cursor-pointer",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.prevMonth && _ctx.prevMonth(...args)))
        }),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.currentMonthYear.capitalizeFirst()), 1),
        _createElementVNode("i", {
          class: "fa-solid fa-chevron-circle-right text-secondary fa-xl cursor-pointer",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.nextMonth && _ctx.nextMonth(...args)))
        })
      ]),
      _createElementVNode("p", {
        class: "text-subtitle text-secondary cursor-pointer mb-0",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.changeToToday && _ctx.changeToToday(...args)))
      }, " Hoje ")
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.weekDays, (day) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "d-flex justify-content-center w-100 py-3",
          key: day
        }, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(day), 1)
        ]))
      }), 128))
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.calendar, (week, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "d-flex justify-content-around",
        key: index
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(week, (day, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass([{ 'cursor-pointer': day.enabled }, "d-flex justify-content-center w-100 py-3 pb-1 flex-column align-items-center"]),
            onClick: ($event: any) => (day.enabled ? _ctx.onDayClick(day) : null)
          }, [
            _createElementVNode("span", {
              class: _normalizeClass([_ctx.getDayStyleClasses(day), "d-flex justify-content-center align-items-center"]),
              style: {"width":"2rem","height":"2rem"}
            }, _toDisplayString(day.day), 3),
            _createElementVNode("div", _hoisted_8, [
              (day.hasPendingAppointment)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9))
                : _createCommentVNode("", true),
              (day.hasAcceptedAppointment)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10))
                : _createCommentVNode("", true),
              (day.hasConcludedAppointment)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11))
                : _createCommentVNode("", true),
              (
              !day.hasPendingAppointment &&
              !day.hasAcceptedAppointment &&
              !day.hasConcludedAppointment
            )
                ? (_openBlock(), _createElementBlock("div", _hoisted_12))
                : _createCommentVNode("", true)
            ])
          ], 10, _hoisted_7))
        }), 128))
      ]))
    }), 128))
  ]))
}