import { toggleOffcanvas } from "@/components/OffcanvasComponent.vue";
import AppointmentService from "@/services/AppointmentService";
import { useMainStore } from "@/store";
import useVuelidate from "@vuelidate/core";
import { helpers, maxLength, minLength, required, requiredIf } from "@vuelidate/validators";
import moment from "moment";
import { mapState } from "pinia";
import { defineComponent } from "vue";
import { EventBusNames } from '@/utils/EventBus';

export default defineComponent({
    setup() {
        return { v$: useVuelidate() }
    },
    computed: {
        ...mapState(useMainStore, ['session'])
    },
    created() {
        this.currentPrice = this.session?.appointment_price.toCurrency() || ''
        this.newPrice = this.session?.appointment_price.toCurrency() || ''
    },
    data() {
        return {
            submitting: false,
            currentPrice: '' as String,
            newPrice: '' as String,
            discount: 'R$0,00' as String,
            percentOff: '',
            limitValue: '',
            redeemByPlaceholder: moment().add(90, 'days').format('DD/MM/YYYY'),
            minimumPrice: AppointmentService.MINIMUM_PRICE,
            form: {
                code: '',
                percent_off: '',
                has_redemption: false,
                has_limit: false,
                redeem_by: '',
                max_redemptions_customers: ''
            },
        };
    },
    validations() {
        return {
            form: {
                code: {
                    required: helpers.withMessage('Informe um código válido', required),
                    minLength: helpers.withMessage('Informe um código com no mínimo 6 caracteres', minLength(6)),
                    maxLength: helpers.withMessage('Informe um código com no máximo 20 caracteres', maxLength(20))
                },
                percent_off: {
                    required: helpers.withMessage('Informe a porcentagem do desconto', required),
                    minAndMaxValue: helpers.withMessage('Informe um valor entre 5% e 90%', (value: any) => {
                        let v = parseInt(value)
                        return v >= 5 && v <= 90;
                    }),
                    sessionValue: helpers.withMessage(`Valor final não pode ser inferior a R$${this.minimumPrice}.`, (value: any) => {
                        let sessionValue = this.session?.appointment_price || 0;
                        let disc = (parseInt(value) / 100) * sessionValue;
                        let vwd = sessionValue - disc;
                        if (disc > 0) {
                            this.discount = disc.toCurrency();
                        }
                        if (vwd > 0) {
                            this.newPrice = vwd.toCurrency()
                        }
                        return vwd >= this.minimumPrice;
                    })
                },
                has_redemption: {},
                has_limit: {},
                redeem_by: {
                    requiredIf: helpers.withMessage('Informe a data de validade do código', requiredIf(() => this.form.has_redemption)),
                    isFuture: helpers.withMessage('Informe uma data válida', (value: any) => {
                        if (!value) return true;
                        let split = value.split("/");
                        let date = `${split[2]}-${split[1]}-${split[0]}`;
                        return moment(date).isValid() && moment(date).isAfter();
                    })
                },
                max_redemptions_customers: {
                    requiredIf: helpers.withMessage('Informe o limite de uso do código', requiredIf(() => this.form.has_limit)),
                }
            }
        }
    },
    methods: {
        handlePercentOff(event: any) {
            let text = event.target.value
            let t = text.clear();
            if (t && t.length > 2) return;
            if (this.percentOff.length > text.length) {
                t = text.substring(0, text.length - 1);
            }
            this.percentOff = t.toPercentage()
            this.form.percent_off = t.toPercentage()
        },
        handleLimit(event: any) {
            let text = event.target.value
            let t = text.replace("x", "");
            if (t && t.length > 3) return;
            if (this.limitValue.length > text.length) {
                t = text.substring(0, text.length - 1);
            }
            this.limitValue = t.toQuantity();
            this.form.max_redemptions_customers = t.toQuantity();
        },
        async handleSubmit() {
            try {
                this.submitting = true
                await this.$couponsService.create(this.form);
                toggleOffcanvas('offcanvas_views')
                useMainStore().clearOffcanvasConfig()
                this.$eventBus.$emit(EventBusNames.UPDATE_COUPONS)
                this.$eventBus.$emit(EventBusNames.SHOW_TOAST, {
                    title: 'Sucesso',
                    msg: 'Código criado com sucesso',
                })
            } catch (error: any) {
                alert(error.message || "Ocorreu um erro no servidor");
            } finally {
                this.submitting = false
            }
        },
        handleHasRedemption(event: any) {
            if (!event.target.checked) {
                this.form.redeem_by = ''
                this.v$.form.redeem_by.$reset()
            }
        },
        handleHasLimit(event: any) {
            if (!event.target.checked) {
                this.form.max_redemptions_customers = ''
                this.v$.form.max_redemptions_customers.$reset()
            }
        }
    }
})