import { useMainStore } from "@/store";
import { mapState } from "pinia";
import { defineComponent } from "vue";
import { EventBusNames } from '@/utils/EventBus';

export default defineComponent({
    mounted() {
        this.avatar = useMainStore().session?.profile_image || ''
    },
    data() {
        return {
            submitting: false,
            uploading: false,
            avatar: '',
            file: null
        }
    },
    computed: {
        ...mapState(useMainStore, ['session']),
        placeholder() {
            return useMainStore().session?.gender === 'female' ? require('@/assets/img/avatars/1.png') : require('@/assets/img/avatars/2.png')
        },
    },
    methods: {
        async onAvatarUploaded(event: any) {
            try {
                this.uploading = true
                this.avatar = event.base64
                this.file = event.file
            } catch (error) {
                console.error(error)
            } finally {
                this.uploading = false
            }
        },
        async updateProfileImage() {
            try {
                this.submitting = true
                await this.$userService.updateProfileImage({ profile_image: this.file })
                await this.$userService.syncSession()
                this.$eventBus.$emit(EventBusNames.SHOW_TOAST, {
                    title: "Alterações feitas",
                    msg: "Suas informações foram salvas",
                })
            } catch (error) {
                console.error(error)
            } finally {
                this.submitting = false
            }
        },
    }
})