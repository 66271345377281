import { useMainStore } from "@/store";
import useVuelidate from "@vuelidate/core";
import { mapState } from "pinia";
import { defineComponent } from "vue";
import { EventBusNames } from '@/utils/EventBus';

export default defineComponent({
    mounted() {
        this.form.gender = this.session?.gender || '';
    },
    data() {
        return {
            options: [
                { id: 'female', name: 'Feminino' },
                { id: 'male', name: 'Masculino' },
                { id: 'other', name: 'Outro' },
            ],
            form: {
                gender: ''
            },
            v$: useVuelidate(),
            submitting: false
        }
    },
    computed: {
        ...mapState(useMainStore, ['session'])
    },
    validations: {
        form: {
            gender: {}
        }
    },
    methods: {
        async submit() {
            try {
                this.submitting = true
                await this.$userService.update(this.form);
                this.$eventBus.$emit(EventBusNames.SHOW_TOAST, {
                    title: "Alterações feitas",
                    msg: "Suas informações foram salvas",
                })
            } catch (error) {
                console.error(error)
            } finally {
                this.submitting = false
            }
        }
    }
});