import { useMainStore } from "@/store";
import { EventBusNames } from "@/utils/EventBus";
import { toggleModal } from "@/utils/Helper";
import { EOffcanvasViews, OffcanvasViewKey, navigateCanvas } from "@/views/OffcanvasViews/OffcanvasViewsConfig";
import { defineComponent } from "vue";

export default defineComponent({
    mounted() {
        this.loading = true
        this.getPlanDetails()

        this.$eventBus.$on(EventBusNames.REFRESH_MY_PLAN, () => {
            this.getPlanDetails()
        })
    },
    data() {
        return {
            mySub: null as any,
            loading: false,
            items: [] as any[],
            submitting: false,
            manualPaying: false,
            reactivating: false,
            errorMessage: ''
        }
    },
    computed: {
        session() {
            return useMainStore().session;
        },
        toggle() {
            return window.innerWidth > 964;
        }
    },
    methods: {
        async getPlanDetails() {
            try {
                await this.$userService.syncSession()
                const subscription = this.session?.subscription;

                if (subscription?.id) {
                    let res = await this.$subscriptionService.show(subscription?.id);
                    let data = this.$subscriptionService.prepareSubscriptionInfo(res.data);

                    this.mySub = data;

                    this.items = [
                        {
                            key: "Status",
                            value: data.statusLabel,
                            subvalue:
                                data.hasCanceled && data.isActive
                                    ? {
                                        label: "Sua assinatura será encerrada em breve",
                                        sublabel: `Você tem até o dia ${data.cancellationDate} para usufruir das funcionalidades como assinante.`,
                                    }
                                    : null,
                        },
                    ];

                    if (data.isActive && !data.isManual) {
                        this.items = [
                            ...this.items,
                            {
                                key: "Desde",
                                value: data.subscriptionDate,
                            },
                            {
                                key: "Cartão",
                                value: data.creditCardLast4,
                            },
                        ];
                    }
                } else {
                    this.items = [
                        {
                            key: "Status",
                            value: "Gratuito",
                        },
                    ];
                    this.mySub = null;
                }
            } catch (error: any) {
                console.log("ERRO NO GET PLAN DETAILS NA MY PLAN SCREEN", error);
                alert(error.message);
            } finally {
                this.loading = false
            }
        },
        async handleCancel() {
            try {
                const subsId = this.session?.subscription?.id
                if (subsId) {
                    this.submitting = true

                    await this.$subscriptionService.cancelSubscription(subsId);

                    toggleModal('cancel_subs')

                    this.$eventBus.$emit(EventBusNames.SHOW_TOAST, {
                        title: "Assinatura cancelada",
                        msg: `Sua assinatura Psiapp+ foi cancelada com sucesso.`,
                    });

                    this.getPlanDetails();
                }
            } catch (error: any) {
                console.log(error);
                alert(error.message)
            } finally {
                this.submitting = false
            }
        },
        handleCreditCards() {
            this.$eventBus.$emit(EventBusNames.GET_CREDIT_CARDS)
            navigateCanvas(EOffcanvasViews.credit_cards as OffcanvasViewKey, null, this.toggle)
        },
        handlePsiappPlus() {
            toggleModal('subscription_modal')
        },
        handleToggleModal() {
            toggleModal('cancel_subs')
        },
        async handleManualPay() {
            try {
                this.manualPaying = true
                await this.$subscriptionService.manualPay(this.mySub?.id);
                this.getPlanDetails()
            } catch (error: any) {
                console.log(error);
                alert(error.message)
            } finally {
                this.manualPaying = false
            }
        },
        async handleReactivate() {
            try {
                this.reactivating = true
                await this.$subscriptionService.reactivate(this.mySub?.id);
                this.getPlanDetails();
            } catch (error: any) {
                console.log(error);
                alert(error.message)
            } finally {
                this.reactivating = false
            }
        }
    }
})