<template>
  <div class="card">
    <div class="card-header">
      <p class="text-header mb-0">Conta bancária</p>
      <div class="dropdown">
        <a
          class="dropdown-toggle text-subtitle text-secondary"
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Ações
        </a>

        <ul class="dropdown-menu">
          <li>
            <span
              class="dropdown-item text-paragraph cursor-pointer"
              @click="handleEdit"
            >
              {{ editing ? "Cancelar alteração" : "Alterar conta bancária" }}
            </span>
          </li>
          <hr class="my-2" />
          <li>
            <span
              class="dropdown-item text-paragraph cursor-pointer"
              @click="onChangeAccountType"
              >{{
                connectAccount?.business_type === "individual"
                  ? "Alterar para conta de pessoa jurídica"
                  : "Alterar para conta de pessoa física"
              }}</span
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="card-body">
      <LoadingContainer v-if="loading" />
      <template v-else>
        <div class="row">
          <template v-if="connectAccount">
            <div class="col-12">
              <TextInput
                :readOnly="true"
                label="Tipo de conta"
                :value="connectAccount?.business_type_name"
              />
            </div>
            <div class="col-12">
              <TextInput
                :readOnly="true"
                label="Nome do titular"
                :value="connectAccount?.legal_name"
              />
            </div>
          </template>
          <div class="col-12">
            <div class="card bg-warning-soft" v-if="editing">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <i class="fa-solid fa-circle-exclamation me-2 text-warning" />
                  <p class="mb-0 text-subtitle">Importante</p>
                </div>
                <p class="text-paragraph">
                  Só é aceito contas correntes. Não aceitamos cadastro de contas
                  poupança ou salário.
                </p>
              </div>
            </div>
          </div>

          <div class="col-12">
            <SelectInput
              v-if="editing"
              required
              :model="v$?.form?.bank_code"
              :options="banks"
              label="Banco"
              :errorMsg="v$.form?.bank_code.required?.$message"
            />
            <TextInput
              v-else
              :readOnly="true"
              label="Banco"
              :value="bankAccount?.bank_name"
            />
          </div>

          <div class="col-12 col-lg-8">
            <TextInput
              :readOnly="!editing"
              label="Agência"
              required
              :model="v$?.form?.agencia"
              :value="bankAccount?.agencia"
              type="text"
              placeholder="Qual a agência?"
              :errorMsg="v$.form?.agencia.required?.$message"
            />
          </div>

          <div class="col-12 col-lg-4">
            <TextInput
              :readOnly="!editing"
              label="Dígito da agência"
              :model="v$.form?.agencia_dv"
              :value="bankAccount?.agencia_dv"
              type="text"
              placeholder="Dígito"
            />
          </div>
          <div class="col-12 col-lg-8">
            <TextInput
              :readOnly="!editing"
              label="Conta"
              required
              :model="v$.form?.conta"
              :value="bankAccount?.conta"
              type="text"
              placeholder="Qual a conta?"
              :errorMsg="v$.form?.conta.required?.$message"
            />
          </div>
          <div class="col-12 col-lg-4">
            <TextInput
              :readOnly="!editing"
              label="Dígito da conta"
              :model="v$.form?.conta_dv"
              :value="bankAccount?.conta_dv"
              type="text"
              placeholder="Dígito"
            />
          </div>
          <div class="col-12" v-if="editing">
            <div class="row">
              <div class="col-12 col-lg-6">
                <Button
                  label="Cancelar"
                  color="del"
                  @click="handleEdit"
                  fullWidth
                />
              </div>
              <div class="col-12 col-lg-6">
                <FormButton
                  label="Concluir"
                  :loading="submitting"
                  :disabled="v$.$invalid"
                  class="mb-3"
                  @onClick="handleUpdateBankAccount"
                  :form="v$?.form"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
  <ModalComponent id="update_bank_account_warning" title="Importante">
    <template v-slot:body>
      <p class="text-paragraph">
        {{ warning }}
      </p>
    </template>
    <template v-slot:footer>
      <Button label="Continuar" @onClick="handleConfirmEdit" />
    </template>
  </ModalComponent>

  <ModalComponent id="update_account_type_warning" title="Importante">
    <template v-slot:body>
      <p class="text-paragraph">
        Para alterar o tipo de conta bancária precisamos que você forneça dados
        adicionais. Sessões que já foram agendadas permanecerão vinculadas a sua
        conta atual. Somente novas sessões serão vinculadas a nova conta
        bancária.
      </p>
    </template>
    <template v-slot:footer>
      <Button
        label="Continuar"
        @onClick="handleChangeAccountType"
        :loading="submitting"
      />
    </template>
  </ModalComponent>
</template>

<script lang="ts">
import BankAccountController from "./BankAccountController";
export default BankAccountController;
</script>

<style scoped></style>
