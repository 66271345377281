<template>
  <div class="card">
    <div class="card-header">
      <span class="text-header">Recibo da sessão</span>
    </div>
    <div class="card-body">
      <ul class="list-group list-group-flush">
        <li
          class="list-group-item d-flex justify-content-between align-items-center"
          :class="{ 'border-0': !item.receipt }"
        >
          <span class="text-paragraph">CPF</span>
          <span v-if="item?.user?.document_number" class="text-subtitle">{{
            item?.user?.document_number
          }}</span>
        </li>
        <li
          v-if="item?.receipt"
          @click="handleReceipt"
          class="list-group-item d-flex justify-content-between align-items-center border-0 cursor-pointer"
        >
          <span class="text-subtitle"
            ><i class="fa-solid fa-receipt me-2"></i>Visualizar recibo</span
          >
          <i class="fa-solid fa-chevron-right text-subtitle"></i>
        </li>
      </ul>
      <Button
        :label="item?.receipt ? ' Remover recibo' : 'Anexar recibo'"
        @onClick="
          item?.receipt ? handleRemoveReceipt() : openFileUploader(inputId)
        "
        :outline="!!item.receipt"
        fullWidth
        :leftIcon="item?.receipt ? '' : 'fa-solid fa-plus-circle'"
        :loading="uploading"
      />
      <FileUploader
        @fileUploaded="handleUploadFile($event)"
        :inputId="inputId"
        :hiddenInput="true"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { openFileUploader } from "@/components/FileUploader/FileUploaderController";
import { EventBusNames } from "@/utils/EventBus";
import { defineComponent } from "vue";

export default defineComponent({
  props: ["item", "callback"],
  data() {
    return {
      uploading: false,
      inputId: "receipt_file_input",
      openFileUploader: (id: string) => openFileUploader(id),
    };
  },
  methods: {
    handleReceipt() {
      window.open(
        `${process.env.VUE_APP_IMG_BASE_URL}${this.item?.receipt}`,
        "_blank"
      );
    },
    async handleUploadFile(file: any) {
      try {
        this.uploading = true;
        const params = { receipt: file.file };
        await this.$appointmentService.updateFile(this.item?.id, params);
        this.$eventBus.$emit(EventBusNames.SHOW_TOAST, {
          title: "Recibo anexado",
          msg: "Recibo foi anexado e você pode visualizar na tela de detalhes.",
        });
        this.callback();
      } catch (error) {
        console.error(error);
      } finally {
        this.uploading = false;
      }
    },
    async handleRemoveReceipt() {
      try {
        this.uploading = true;
        const params = { remove_receipt: true };
        await this.$appointmentService.updateFile(this.item?.id, params);
        this.$eventBus.$emit(EventBusNames.SHOW_TOAST, {
          title: "Recibo removido",
          msg: "O recibo foi removido com sucesso.",
        });
        this.callback();
      } catch (error) {
        console.error(error);
      } finally {
        this.uploading = false;
      }
    },
  },
});
</script>

<style scoped></style>
