<template>
  <div class="card">
    <div class="card-header">
      <p class="text-header mb-0">Gerenciar perfil</p>
    </div>
    <div class="card-body">
      <!-- MELHORES SEU PERFIL -->
      <div class="card cursor-pointer" @click="handleImproveYourProfile">
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between mb-3">
            <p class="mb-0 text-subtitle">
              <i class="fa-solid fa-star"></i>
              Melhore seu perfil
            </p>
            <i class="fa-solid fa-chevron-right"></i>
          </div>
          <p class="text-paragraph">
            Dicas para deixar seu perfil mais atrativo para os pacientes.
          </p>

          <div class="progress">
            <div
              class="progress-bar bg-success"
              role="progressbar"
              :style="{
                width: `${session?.profile_improvement_values?.percentage}%`,
              }"
            ></div>
          </div>
        </div>
      </div>

      <!-- VISUALIZAÇÕES -->
      <div class="card">
        <div class="card-body">
          <p class="text-subtitle">
            <i class="fa-solid fa-eye"></i>
            Visualizações
          </p>
          <p class="text-paragraph">
            Quantidade de pacientes que visualizaram seu perfil.
          </p>
          <div
            class="d-flex align-items-center justify-content-between border-bottom my-4"
          >
            <p class="text-paragraph">Últimos 30 dias</p>
            <p class="text-subtitle">
              {{ session?.total_views_this_month }}
            </p>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <p class="text-paragraph">Todas visualizações</p>
            <p class="text-subtitle">{{ session?.total_views }}</p>
          </div>
        </div>
      </div>

      <!-- PSIAPP ADS -->
      <div class="card bg-warning-soft">
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between">
            <img
              src="@/assets/img/psiapp_ads.svg"
              alt=""
              height="26"
              class="mb-3"
            />

            <p class="text-subtitle">Em breve</p>
          </div>
          <p class="text-paragraph">
            Alcance os pacientes onde eles estiverem e aumente ainda mais a
            visibilidade pro seu perfil.
          </p>
          <button class="btn btn-warning" @click="handlePsiappAds">
            Quero saber mais
          </button>
        </div>
      </div>

      <!-- ACTIONS -->
      <div
        class="d-flex align-items-center py-4 cursor-pointer"
        :class="{ 'border-bottom': index !== actions.length - 1 }"
        v-for="(action, index) in actions"
        :key="index"
        @click="action.action"
      >
        <p class="mb-0 text-paragraph">
          <i :class="action.icon" class="text-primary"></i>
          {{ action.label }}
        </p>
        <i class="fa-solid fa-chevron-right ms-auto text-primary"></i>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useMainStore } from "@/store";
import { EventBus, EventBusNames } from "@/utils/EventBus";
import {
  IOffcanvasConfig,
  OffcanvasViewKey,
  OffcanvasViewsConfig,
  navigateCanvas,
} from "@/views/OffcanvasViews/OffcanvasViewsConfig";
import { computed, defineComponent } from "vue";

export default defineComponent({
  setup() {
    const store = useMainStore();
    const session = computed(() => store.session);
    const profileLink = `${process.env.VUE_APP_WEB_APP_URL}psi/${session.value?.slug}`;
    const toggle = computed(() => window.innerWidth > 964);

    const actions = [
      {
        icon: "fa-solid fa-user",
        label: "Visualizar meu perfil",
        action: () => navigate(OffcanvasViewsConfig.my_profile),
      },
      {
        icon: "fa-solid fa-globe",
        label: "Visualizar meu perfil na web",
        action: () => window.open(profileLink, "_blank"),
      },
      {
        icon: "fa-solid fa-user-pen",
        label: "Gerenciar e editar perfil",
        action: () => navigate(OffcanvasViewsConfig.profile_edit),
      },
      {
        icon: "fa-solid fa-share",
        label: "Compartilhar horário avulso",
        action: () => navigate(OffcanvasViewsConfig.share_schedule),
      },
      {
        icon: "fa-solid fa-share",
        label: "Compartilhar perfil",
        action: () => {
          navigator.clipboard.writeText(profileLink);
          EventBus.$emit(EventBusNames.SHOW_TOAST, {
            title: "Link copiado",
            msg: `O link de seu perfil foi copiado para você poder compartilhar`,
          });
        },
      },
    ];

    const handlePsiappAds = () => {
      const phone = process.env.VUE_APP_WHATSAPP_NUMBER;
      const msg = process.env.VUE_APP_WHATSAPP_MESSAGE;
      const uri = `https://api.whatsapp.com/send?phone=${phone}&text=${msg}`;
      window.open(uri, "_blank");
    };

    const handleImproveYourProfile = () => {
      navigate(OffcanvasViewsConfig.improve_your_profile);
    };

    const navigate = (view: IOffcanvasConfig) => {
      navigateCanvas(view.path as OffcanvasViewKey, null, toggle.value);
    };

    return {
      actions,
      handlePsiappAds,
      session,
      handleImproveYourProfile,
    };
  },
});
</script>

<style scoped></style>
