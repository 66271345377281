import { useMainStore } from "@/store";
import LanguagesObj from "@/utils/LanguagesObj";
import useVuelidate from "@vuelidate/core";
import { mapState } from "pinia";
import { defineComponent } from "vue";
import { EventBusNames } from '@/utils/EventBus';

export default defineComponent({
    mounted() {
        this.loading = true
        this.getLanguages()
    },
    data() {
        return {
            v$: useVuelidate(),
            loading: false,
            submitting: false,
            languages: [] as any,
            form: {
                languages: [] as any,
            }
        }
    },
    computed: {
        ...mapState(useMainStore, ['session']),
    },
    validations: {
        form: {
            languages: {}
        }
    },
    methods: {
        async getLanguages() {
            try {
                const res = await this.$userService.languages()
                this.languages = res.data.map((elem: any) => ({
                    ...elem,
                    selected: this.session?.languages.some((sl: any) => sl.language_id == elem.id),
                    name: `${LanguagesObj[elem.code].flag} ${elem.name}`
                }));
                this.form.languages = this.session?.languages.map((elem: any) => elem.language_id)
            } catch (error) {

            } finally {
                this.loading = false;
            }
        },
        async submit() {
            try {
                this.submitting = true

                const params = {
                    languages: this.languages
                        .map((elem: any) => ({
                            ...elem,
                            selected: this.form.languages.some((fl: number) => fl == elem.id),
                        }))
                        .filter((elem: any) => elem.selected)
                        .map((elem: any) => elem.id),
                }

                await this.$userService.update(params)
                this.$eventBus.$emit(EventBusNames.SHOW_TOAST, {
                    title: "Alterações feitas",
                    msg: "Suas informações foram salvas",
                })
            } catch (error) {
                console.error(error)
            } finally {
                this.submitting = false
            }
        }
    }
})