<template>
  <div
    class="d-flex align-items-center"
    v-if="item.status == appointmentStatus.AGUARDANDO"
  >
    <Button
      :marginVertical="false"
      fullWidth
      @onClick="handleCancelPressed"
      label="Cancelar"
      outline
      outlineColor="danger"
      :loading="isSubmittingCancel"
      class="me-2"
    />
    <Button
      :marginVertical="false"
      class="ms-2"
      fullWidth
      @onClick="handleAccept"
      label="Aceitar sessão"
      :loading="isSubmittingConfirm"
    />
  </div>

  <div
    class="d-flex align-items-center justify-content-between"
    v-if="
      item.status == appointmentStatus.AGENDADO ||
      item.status == appointmentStatus.ANDAMENTO
    "
  >
    <p class="text-subtitle mb-0">
      {{
        item?.status == appointmentStatus.ANDAMENTO
          ? `Sessão iniciada`
          : `Aguardando início`
      }}
    </p>
    <Button
      :marginVertical="false"
      @onClick="handleScheduledAppointment"
      label="Iniciar sessão"
      :disabled="!item?.can_enter"
      :loading="isSubmittingConfirm"
    />
  </div>

  <div
    class="d-flex align-items-center justify-content-between"
    v-if="
      item?.type == appointmentTypes.IMEDIATE &&
      (item?.status == appointmentStatus.ANDAMENTO ||
        item?.status == appointmentStatus.PENDING)
    "
  >
    <Button
      :marginVertical="false"
      v-if="item.status == appointmentStatus.PENDING"
      fullWidth
      outline
      class="me-2"
      @onClick="handleCancelPressed"
      :loading="isSubmittingCancel"
      label="Recusar"
    />
    <p v-else class="text-subtitle mb-0">Sessão iniciada</p>

    <Button
      :marginVertical="false"
      :fullWidth="item.status == appointmentStatus.PENDING"
      :class="{ 'ms-2': item.status == appointmentStatus.PENDING }"
      :label="
        item.status == appointmentStatus.PENDING
          ? 'Iniciar sessão'
          : 'Retomar sessão'
      "
      :loading="comingIn"
      :disabled="!item.can_enter"
      @onClick="handleScheduledAppointment"
    />
  </div>

  <ModalComponent
    backdrop="false"
    :id="modalId"
    title="Deseja aceitar esta sessão e as futuras?"
  >
    <template v-slot:body>
      <p class="text-paragraph">
        Aceitando essa sessão você estará aceitando sessões que acontecerão
        {{ item?.recurrency?.frequencyText }}
      </p>

      <div
        class="d-flex py-4 align-items-center justify-content-between border-bottom"
      >
        <p class="text-paragraph mb-0">Período</p>
        <p class="text-subtitle mb-0">
          {{ item?.recurrency?.frequencyLabel }}
        </p>
      </div>
    </template>
    <template v-slot:footer>
      <Button outline label="Não, voltar" @onClick="toggleModal(modalId)" />
      <Button
        label="Aceitar esta e as próximas sessões"
        @onClick="handleConfirmPressed"
        :loading="isSubmittingConfirm"
      />
    </template>
  </ModalComponent>
</template>

<script lang="ts">
import AppointmentService from "@/services/AppointmentService";
import { toggleModal } from "@/utils/Helper";
import { closeOffCanvas } from "@/views/OffcanvasViews/OffcanvasViewsConfig";
import { defineComponent } from "vue";
import { EventBusNames } from "../../../utils/EventBus";

export default defineComponent({
  data() {
    return {
      isSubmittingCancel: false,
      isSubmittingConfirm: false,
      comingIn: false,
      appointmentStatus: AppointmentService.STATUS,
      appointmentTypes: AppointmentService.TYPES,
      modalId: "acceptance_modal",
      toggleModal: (id: string) => toggleModal(id),
    };
  },
  props: ["item", "callback"],
  methods: {
    handleCancelPressed() {
      toggleModal("cancel_appointment_modal");
    },
    handleAccept() {
      if (this.item.recurrency) {
        this.handleAcceptPressed();
      } else {
        this.handleConfirmPressed();
      }
    },
    async handleScheduledAppointment() {
      try {
        if (this.item?.type == "imediate") {
          this.comingIn = true;
          await this.$appointmentService.show(this.item.id);
          this.handleGoToRoom();
        } else {
          this.handleGoToRoom();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.comingIn = false;
      }
    },
    async handleGoToRoom() {
      this.$router.push(`${this.$paths.appointments.room}/${this.item.id}`);
      closeOffCanvas();
    },
    async handleAcceptPressed() {
      try {
        if (this.isSubmittingConfirm || this.isSubmittingCancel) return;
        const params = {
          interval: this.item.recurrency.interval,
          frequency: this.item.recurrency.frequency,
          date: this.item.recurrency.reference_date,
        };
        const res = await this.$appointmentService.recurrenciesVerification(
          params
        );
        if (res.data.can_schedule) {
          toggleModal(this.modalId);
        } else {
          alert(
            "Ops! Horário já possui outras sessões agendadas. O horário solicitado coincide com uma sessão já agendada no futuro"
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    async handleConfirmPressed() {
      try {
        if (this.isSubmittingConfirm || this.isSubmittingCancel) return;

        this.isSubmittingConfirm = true;

        await this.$appointmentService.update(this.item?.id, {
          status: "agendado",
        });
        this.callback();
        this.$eventBus.$emit(EventBusNames.UPDATE_APPOINTMENTS);
        await this.$userService.syncSession();

        if (this.item.recurrency) toggleModal(this.modalId);
      } catch (error) {
        console.error(error);
      } finally {
        this.isSubmittingConfirm = false;
      }
    },
  },
});
</script>

<style scoped></style>
