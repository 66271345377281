import { IConfirmationAlert } from '@/components/ConfirmationAlert/ConfirmationAlertController';
import mitt, { Emitter } from 'mitt';

export enum EventBusNames {
    SHOW_CONFIRMATION_ALERT = 'SHOW_CONFIRMATION_ALERT',
    SHOW_TOAST = "SHOW_TOAST",
    UPDATE_APPOINTMENTS = "UPDATE_APPOINTMENTS",
    CALENDAR_DATE_CHANGED = 'CALENDAR_DATE_CHANGED',
    GET_CREDIT_CARDS = 'GET_CREDIT_CARDS',
    CLEAR_IMAGE_CROPPER = 'CLEAR_IMAGE_CROPPER',
    CLEAR_FILE_UPLOADER = 'CLEAR_FILE_UPLOADER',
    UPDATE_CHATS = 'UPDATE_CHATS',
    UPDATE_HOME = 'UPDATE_HOME',
    UPDATE_COUPONS = 'UPDATE_COUPONS',
    REFRESH_MY_PLAN = 'REFRESH_MY_PLAN',
    ON_HOLDING_UPDATE = 'ON_HOLDING_UPDATE',
}

export type Events = {
    [EventBusNames.SHOW_CONFIRMATION_ALERT]: IConfirmationAlert
    [EventBusNames.SHOW_TOAST]: { msg: string, title: string };

    [EventBusNames.UPDATE_APPOINTMENTS]: void;
    [EventBusNames.CALENDAR_DATE_CHANGED]: { date: string };
    [EventBusNames.GET_CREDIT_CARDS]: void;
    [EventBusNames.CLEAR_IMAGE_CROPPER]: void;
    [EventBusNames.CLEAR_FILE_UPLOADER]: void;
    [EventBusNames.UPDATE_CHATS]: void;
    [EventBusNames.UPDATE_HOME]: void;
    [EventBusNames.UPDATE_COUPONS]: void;
    [EventBusNames.REFRESH_MY_PLAN]: void;
    [EventBusNames.ON_HOLDING_UPDATE]: void;
}

// Create the event bus
const emitter: Emitter<Events> = mitt<Events>();

// Export the event bus for use in components
export const EventBus = {
    $on: emitter.on,
    $off: emitter.off,
    $emit: emitter.emit,
};