
import { ESettingsType } from '@/enums/ESettingsType'
import ILogin from '@/interfaces/ILogin'
import ISession, { IBankAccount } from '@/interfaces/ISession'
import { replacePhoneNumberToHash, toggleModal, toPattern } from '@/utils/Helper'
import PhoneCodes from '@/utils/PhoneCodes'
import { unformat } from 'v-money3'
import AppointmentService from './AppointmentService'
import NetworkService, { Endpoint } from './NetworkService'
import { useMainStore } from '@/store'
import Banks from '@/utils/Banks'
import { ILanguage } from '@/interfaces/ILanguage'
import moment from 'moment'
import LanguagesObj from '@/utils/LanguagesObj'

export default class UserService {
  private network
  constructor() {
    this.network = new NetworkService()
  }

  static TOTAL_REGISTER_STEPS = 5
  static GENDER_OPTIONS = [
    { id: 'female', title: 'Feminino' },
    { id: 'male', title: 'Masculino' },
    { id: 'other', title: 'Outro' },
  ]

  list = (params: any) => {
    return this.network.get(Endpoint.users, params)
  }
  prepareMoneyValue(value: string) {
    return unformat(value, {
      prefix: "R$ ",
      thousands: "",
      decimal: ",",
      precision: 2,
      disableNegative: true,
      minimumNumberOfCharacters: 1,
      modelModifiers: {
        number: true,
      }
    })
  }
  prepareParams(params: any) {
    if (params?.appointment_price) {
      params.appointment_price = this.prepareMoneyValue(params.appointment_price)
    }
    if (params?.first_appointment_price) {
      params.first_appointment_price = this.prepareMoneyValue(params.first_appointment_price)
    }
    if (params?.phone) {
      params.phone = params?.phone.clear()
    }

    params.account_link = true
    params.web = true

    return params
  }
  create = async (params: any) => {
    const values = this.prepareParams(params)
    const res = await this.network.postMultipart(Endpoint.users, values)
    if (res.access_token) {
      await this.prepareUser(res.data, res?.access_token)
    }
    return res
  }
  show = (id: string | number) => {
    return this.network.get(`${Endpoint.users}/${id}`)
  }
  remove = () => {
    const store = useMainStore()
    const id = store.session?.id
    return this.network.delete(`${Endpoint.users}/${id}`)
  }
  update = async (params: any) => {
    const store = useMainStore()
    const id = store.session?.id
    const values = this.prepareParams(params)
    const res = await this.network.put(`${Endpoint.users}/${id}`, values)
    this.syncSession()
    return res
  }
  updateProfileImage = (params: any) => {
    const store = useMainStore()
    const id = store.session?.id
    return this.network.postMultipart(`${Endpoint.users}/${id}/edit`, params)
  }
  login = async (params: ILogin) => {
    const res = await this.network.post(Endpoint.login, params)
    if (['psychologist'].includes(res.data.type)) {
      if (res.access_token) {
        const user = await this.show(res.data.id)
        await this.prepareUser(user.data, res?.access_token)
      }
      return res
    } else {
      throw ({ status: false, message: 'Credenciais inválidas' })
    }
  }
  loginWithBearerToken = async (query: any) => {
    try {
      useMainStore().saveToken(query.token)
      const res = await this.show(query.user_id);
      if (res.data.type === "psychologist") {
        await this.prepareUser(res.data);
        return true;
      }
      return false;
    } catch (error) {
      console.error("UserService.loginWithBearerToken", error);
      return false;
    }
  };
  prepareUser = async (data: ISession, access_token: string = '') => {
    const store = useMainStore()
    const languagesObj = LanguagesObj

    let connect_account_currently_due: string[] = []
    if (data.connect_account) {
      data.connect_account.legal_name = data.connect_account.business_type == 'individual' ? `${data.connect_account.individual_first_name} ${data.connect_account.individual_last_name}` : data.connect_account.company_name
      data.connect_account.business_type_name = data.connect_account.business_type == 'individual' ? 'Pessoa Física' : 'Pessoa Jurídica'

      if (data.connect_account.currently_due) {
        data.connect_account.currently_due.split('|').map((item: string) => {
          if (item === 'external_account') return
          connect_account_currently_due.push(AppointmentService.CONNECT_ACCOUNT_DUE[item])
        })
      }
    }

    const session: ISession = {
      id: data.id,
      name: data.name,
      nickname: data.name.split(' ')[0],
      email: data.email,
      created_at: data.created_at,
      type: data.type,
      profile_image: data.profile_image ? process.env.VUE_APP_IMG_BASE_URL + data.profile_image : this.getAvatar(data.gender == 'male' ? '2' : '1'),
      approved: data.approved,
      stripe_id: data.stripe_id,
      charges_enabled: data.charges_enabled,
      account_pending_verification: data.account_pending_verification,
      account_verification_errors: data.account_verification_errors,
      country_code: data.country_code,
      phone: data.phone,
      formatted_phone: this.formatPhone(data),
      subscription: data.subscription,
      favorite_card: store.session?.favorite_card || null,
      entry_fee: data.entry_fee,
      hours: data.hours,
      appointment_price: data.appointment_price,
      first_appointment_price: data.first_appointment_price,
      online: data.online,
      first_appointment: data.first_appointment,
      block_agenda: data.block_agenda,
      partnerships: data.partnerships,
      total_views: data.total_views,
      total_views_this_month: data.total_views_this_month,
      profile_improvement: data.profile_improvement,
      profile_improvement_values: this.calculateObjPercentage(data.profile_improvement),
      bank_account: this.prepareBankAccount(data.bank_account),
      business_type: data.business_type,
      slug: data.slug,
      is_premium: this.isPremium(data),
      ratings_as_psi_count: data.ratings_as_psi_count,
      formatted_ratings_as_psi_count: `${data?.ratings_as_psi_count || 0} ${data?.ratings_as_psi_count && data?.ratings_as_psi_count == 1
        ? "depoimento"
        : "depoimentos"
        }`,
      valid_appointments_as_psi_count: data.valid_appointments_as_psi_count,
      formatted_valid_appointments_as_psi_count: `${data?.valid_appointments_as_psi_count || 0
        } ${data?.valid_appointments_as_psi_count &&
          data?.valid_appointments_as_psi_count == 1
          ? "sessão"
          : "sessões"
        }`,
      crp: data.crp,
      address_state: data.address_state,
      languages: data.languages,
      formatted_languages: data.languages ? data.languages.map((language: ILanguage) => `${language?.detail?.name} ${languagesObj[language?.detail?.code]?.flag}`) : [],
      bio: data.bio,
      intro_video_link: data.intro_video_link,
      formatted_intro_video_link: `https://youtube.com/watch?v=${data?.intro_video_link}`,
      occupations: data.occupations,
      approaches: data.approaches,
      education: data.education,
      years_exp: data.years_exp,
      formatted_years_exp: `${data?.years_exp} ano(s) de experiência`,
      latest_ratings: data.latest_ratings,
      formatted_latest_ratings: data.latest_ratings ? data.latest_ratings.map((rating: any) => ({
        ...rating,
        formatted_profile_image: rating.user ? this.getAvatar(rating.user.profile_image) : null,
        formatted_date: moment(rating.created_at).format("DD[ de ]MMMM[ de ]YYYY")
      })) : [],
      gender: data.gender,
      epsi: data.epsi,
      document_image: data.document_image ? process.env.VUE_APP_IMG_BASE_URL + data.document_image : '',
      document_in_hand: data.document_in_hand ? process.env.VUE_APP_IMG_BASE_URL + data.document_in_hand : '',
      connect_account: data.connect_account,
      connect_account_currently_due
      // linkedin: data.linkedin
    }
    store.saveSession(session)

    if (access_token) {
      store.saveToken(access_token)
    }
  }
  prepareBankAccount(data: any): IBankAccount {
    let bankAccount = data && data.length ? data[0] : null
    if (bankAccount) {
      const store = useMainStore()
      const session = store.session

      switch (session?.business_type) {
        case 'individual':
          bankAccount.business_type = 'Conta de pessoa física'
          break;
        case 'company':
          bankAccount.business_type = 'Conta de pessoa jurídica'
          break;
        default:
          bankAccount.business_type = '---'
          break;
      }

      bankAccount.bank_name = session?.bank_account?.bank_code
        ? Banks.find(
          (r) => r.id == session?.bank_account?.bank_code
        )?.name
        : ""
    }
    return bankAccount
  }
  updateMyBank = (params: any) => {
    return this.network.post(Endpoint.myBanks, params);
  };
  formatPhone(data: ISession) {
    const phoneCode = PhoneCodes.find(
      (elem) => elem.dial_code == data?.country_code
    );

    const pattern = PhoneCodes.find(
      (elem) => elem.dial_code == data?.country_code
    )?.mask;

    let phoneNumber: String = ''
    if (pattern) {
      phoneNumber =
        data?.country_code == "+55"
          ? data?.phone.formatPhone()
          : toPattern(
            data?.phone,
            "phone",
            replacePhoneNumberToHash(pattern)
          );
    }

    return `${phoneCode?.flag} ${phoneCode?.dial_code} ${phoneNumber}`;
  }
  syncSession = async () => {
    const store = useMainStore()
    const session = store.session
    if (session) {
      const res = await this.show(session.id)
      this.prepareUser(res.data)
    }
  }
  logout = () => {
    const store = useMainStore()
    store.clearSession()
    // store.commit(MUTATIONS.CLEAR_SESSION)
  }
  getTerms = () => {
    return this.network.get(`${Endpoint.termsOfUse}`);
  };
  getPrivacyPolicy = () => {
    return this.network.get(`${Endpoint.privacyPolicy}`);
  };
  getOccupationAreas = (params = {}) => {
    return this.network.get(Endpoint.occupationAreas, params);
  };
  getBanks = () => {
    return this.network.get(Endpoint.banks);
  };
  getAddressFromCep = async (cep: string) => {
    return fetch(`https://viacep.com.br/ws/${cep}/json`).then((r) => r.json());
  };
  updateStripePending = (params: object) => {
    return this.network.postMultipart(Endpoint.updateStripePending, params);
  };
  getHomeInfo = () => {
    return this.network.get(Endpoint.homeInfo);
  }

  getAvatar = (userAvatar: string) => {
    var avatar = require("../assets/img/avatars/1.png");
    switch (String(userAvatar)) {
      case "1":
        avatar = require("../assets/img/avatars/1.png");
        break;
      case "2":
        avatar = require("../assets/img/avatars/2.png");
        break;
      case "3":
        avatar = require("../assets/img/avatars/3.png");
        break;
      case "4":
        avatar = require("../assets/img/avatars/4.png");
        break;
      case "5":
        avatar = require("../assets/img/avatars/5.png");
        break;
      case "6":
        avatar = require("../assets/img/avatars/6.png");
        break;
      case "7":
        avatar = require("../assets/img/avatars/7.png");
        break;
      case "8":
        avatar = require("../assets/img/avatars/8.png");
        break;
      case "9":
        avatar = require("../assets/img/avatars/9.png");
        break;
      case "10":
        avatar = require("../assets/img/avatars/10.png");
        break;
      case "11":
        avatar = require("../assets/img/avatars/11.png");
        break;
      case "12":
        avatar = require("../assets/img/avatars/12.png");
        break;
      case "13":
        avatar = require("../assets/img/avatars/13.png");
        break;
      case "14":
        avatar = require("../assets/img/avatars/14.png");
        break;
      case "15":
        avatar = require("../assets/img/avatars/15.png");
        break;
      case "16":
        avatar = require("../assets/img/avatars/16.png");
        break;
      case "17":
        avatar = require("../assets/img/avatars/17.png");
        break;
      case "18":
        avatar = require("../assets/img/avatars/18.png");
        break;
      case "19":
        avatar = require("../assets/img/avatars/19.png");
        break;
      case "20":
        avatar = require("../assets/img/avatars/20.png");
        break;
      case "21":
        avatar = require("../assets/img/avatars/21.png");
        break;
    }
    return avatar;
  };

  updateAvailabilitySettings = async (type: ESettingsType) => {
    const store = useMainStore()
    const userSession = store.session
    let settingsLoading = {
      block_agenda: false,
      online: false,
      first_appointment: false,
      partnerships: false,
    }
    switch (type) {
      case ESettingsType.first_appointment:
        settingsLoading.first_appointment = true;
        break;
      case ESettingsType.block_agenda:
        settingsLoading.block_agenda = true;
        break;
      case ESettingsType.online:
        let res = await new AppointmentService().list({
          type: "scheduled",
          status: "aguardando",
        });
        if (res.data.length && res.data[0].online && userSession?.online) {
          toggleModal('agenda_warning')
          document.getElementById(type)?.click();
          return;
        }
        settingsLoading.online = true;
        break;
      case ESettingsType.partnerships:
        settingsLoading.partnerships = true;
        break;
    }

    let params: Partial<ISession> = {};


    if (userSession) {
      params[type] = !userSession[type];
    }

    const res = {
      params,
      settingsLoading
    }

    return res
  }
  isPremium = (data?: ISession) => {
    const store = useMainStore()
    const session = data ? data : store.session
    if (session?.subscription) {
      const subscription = session?.subscription;
      const status = session?.subscription?.status;
      const isPremium =
        subscription && (status == "trialing" || status == "active");

      return isPremium;
    }
    return false;
  };
  calculateObjPercentage(obj: any) {
    try {
      if (obj) {
        const values = Object.values(obj);
        const concludedCount = values.filter((value) => value === true).length;
        const totalCount = values.length;
        const percentage = (concludedCount / totalCount) * 100;
        return {
          percentage,
          concludedCount,
          totalCount,
        };
      }
    } catch (error) {
      console.log(error)
    }
  }

  languages = () => {
    return this.network.get(Endpoint.languages);
  };

  getApproaches = () => {
    return this.network.get(Endpoint.approaches);
  };

  availableDays = () => {
    const session = useMainStore().session
    return this.network.get(Endpoint.availableDays, { user_id: session?.id });
  };

  blockDate = (params: any) => {
    return this.network.post(`${Endpoint.specificDaySchedules}`, params);
  };

  getSpecificDaySchedules = () => {
    return this.network.get(`${Endpoint.specificDaySchedules}`);
  };

  availableTimes = async (
    date: string,
    user_id = useMainStore().session?.id
  ) => {
    const now = moment();
    const res = await this.network.get(Endpoint.availableTimes, {
      user_id,
      date,
    });
    let times = res.data
      .filter((t: any) => moment(t).isAfter(now))
      .map((r: any) => {
        const st = moment(r);
        return {
          date: r,
          formattedDate: `${st.format(`dddd`)} das ${st.format(
            `HH[h]mm`
          )} às ${st.add(50, "minutes").format(`HH[h]mm`)}`.capitalizeFirst(),
        };
      });

    if (useMainStore().session?.online) {
      times = [
        {
          date: moment().format("YYYY-MM-DD HH:mm:ss"),
          formattedDate: "Agendar para agora!",
          online: true,
        },
        ...times,
      ];
    }
    return {
      status: true,
      data: times,
    };
  };

  getStripeAccountLink = (id: number) => {
    return this.network.get(`${Endpoint.stripeConnectOnboarding}/${id}`,);
  }

  forgotPassword = (params: { email: string }) => {
    return this.network.post(Endpoint.forgotPassword, params);
  }

  resetPassword = (params: { email: string, password: string, token: string }) => {
    return this.network.post(Endpoint.resetPassword, params)
  }

  stripeConnectReset = (id: number) => {
    return this.network.post(`${Endpoint.stripeConnectReset}/${id}`, {})
  }
}
