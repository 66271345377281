import { useMainStore } from "@/store";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from '@vuelidate/validators';
import { mapState } from "pinia";
import { defineComponent } from "vue";
import { EventBusNames } from '@/utils/EventBus';

export default defineComponent({
    mounted() {
        this.getOccupationAreas()
    },
    data() {
        return {
            loading: true,
            submitting: false,
            v$: useVuelidate(),
            form: {
                approaches: [] as any
            },
            approaches: [] as any,
            allSelected: false,
        }
    },
    computed: {
        ...mapState(useMainStore, ['session']),
    },
    validations() {
        return {
            form: {
                approaches: {
                    required: helpers.withMessage('Selecione até 6 especialidades', required),
                },
            }
        }
    },
    methods: {
        async getOccupationAreas() {
            try {
                const res = await this.$userService.getApproaches()
                this.approaches = res.data.map((elem: any) => ({
                    ...elem,
                    selected: this.session?.approaches.some((occ: any) => occ.approach_id == elem.id),
                }));
                this.form.approaches = this.session?.approaches.map((elem: any) => elem.approach_id)

                this.allSelected = this.approaches.length == this.approaches.filter((e: any) => e.selected).length
            } catch (error) {
                console.log(error)
            } finally {
                this.loading = false
            }
        },
        async submit() {
            try {
                this.submitting = true
                const params = {
                    approaches: this.approaches
                        .map((elem: any) => ({
                            ...elem,
                            selected: this.form.approaches.some((fl: number) => fl == elem.id),
                        }))
                        .filter((elem: any) => elem.selected)
                        .map((elem: any) => elem.id),
                }
                await this.$userService.update(params)
                this.$eventBus.$emit(EventBusNames.SHOW_TOAST, {
                    title: "Alterações feitas",
                    msg: "Suas informações foram salvas",
                })
            } catch (error) {
                console.error(error)
            } finally {
                this.submitting = false
            }
        },
        handleSelect() {
            if (this.form.approaches.length > 6) {
                alert("Você só pode selecionar 6 especialidades");
                this.form.approaches.pop()
            }
        },
        handleSelectAll() {
            this.allSelected = !this.allSelected
            this.approaches = this.approaches.map((app: any) => ({ ...app, selected: this.allSelected }))
            this.form.approaches = this.approaches.filter((app: any) => app.selected).map((app: any) => app.id)
        }
    },

})