import { useMainStore } from '@/store';
import { useVuelidate } from '@vuelidate/core';
import { helpers, minLength, required } from '@vuelidate/validators';
import { mapState } from 'pinia';
import { defineComponent } from "vue";
import { EventBusNames } from '@/utils/EventBus';

export default defineComponent({
    mounted() {
        if (this.session) {
            this.form = {
                education: this.session.education,
                years_exp: this.session.years_exp,
                crp: this.session.crp,
                epsi: this.session.epsi,
                // linkedin: this.session.linkedin,
                bio: this.session.bio,
            }
        }
    },
    computed: {
        ...mapState(useMainStore, ['session'])
    },
    data() {
        return {
            submitting: false,
            v$: useVuelidate(),
            form: {
                education: '',
                years_exp: '',
                crp: '',
                epsi: '',
                // linkedin: '',
                bio: '',
            }
        }
    },
    validations() {
        return {
            form: {
                education: {
                    required: helpers.withMessage('Informe sua formação', required)
                },
                years_exp: {
                    required: helpers.withMessage('Informe sua experiência', required)
                },
                crp: {
                    required: helpers.withMessage('Informe um CRP válido', required),
                    minLength: helpers.withMessage('Informe um CRP válido', minLength(4))
                },
                epsi: {},
                // linkedin: {},
                bio: {
                    required: helpers.withMessage('Informe uma descrição', required)
                },
            }
        }
    },
    methods: {
        async submit() {
            try {
                this.submitting = true
                await this.$userService.update(this.form)
                this.$eventBus.$emit(EventBusNames.SHOW_TOAST, {
                    title: "Alterações feitas",
                    msg: "Suas informações foram salvas",
                })
            } catch (error) {
                console.error(error)
            } finally {
                this.submitting = false
            }
        }
    },
})
