import { toggleModal } from "@/components/ModalComponent.vue";
import { useMainStore } from "@/store";
import LanguagesObj from "@/utils/LanguagesObj";
import { EOffcanvasViews } from '@/views/OffcanvasViews/OffcanvasViewsConfig';
import { mapState } from "pinia";
import { defineComponent } from "vue";
import { navigateCanvas } from "../OffcanvasViewsConfig";
import { EventBusNames } from "@/utils/EventBus";

export default defineComponent({
    data() {
        return {
            languagesObj: LanguagesObj,
            toggleModal: (id: string) => toggleModal(id)
        }
    },
    computed: {
        ...mapState(useMainStore, ['session']),
        approaches() {
            return useMainStore().session?.approaches.slice(0, 3) || []
        },
        specialties() {
            return useMainStore().session?.occupations.slice(0, 3) || []
        }
    },
    methods: {
        handleIntroVideo() {
            window.open(this.session?.formatted_intro_video_link, '_blank')
        },
        handleRatings() {
            navigateCanvas(EOffcanvasViews.ratings, null, false)
        },
        handleShareProfile() {
            if (this.session?.slug) {
                const profileLink = `${process.env.VUE_APP_WEB_APP_URL}psi/${this.session?.slug}`;
                navigator.clipboard.writeText(profileLink);
                this.$eventBus.$emit(EventBusNames.SHOW_TOAST, {
                    title: "Link copiado",
                    msg: `O link de seu perfil foi copiado para você poder compartilhar`,
                });
            }
        }
    }
})